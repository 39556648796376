import { computed, ref } from "@vue/composition-api";

export const refreshKey = ref(0);
export const useLocalStorageString = <T>(key: string, initialValue: T) => {
  const value = computed({
    get: () => {
      refreshKey.value; // forces a recompute when refreshKey changes
      const val = localStorage.getItem(key);
      if (val === null) return initialValue;
      try {
        return JSON.parse(val) as T;
      } catch {
        return initialValue;
      }
    },
    set: x => {
      const stringified = JSON.stringify(x);
      localStorage.setItem(key, stringified);
      refreshKey.value++;
    }
  });

  return value;
};
