








import { Match } from "@/services/evaluation-service";
import { defineComponent, PropType } from "@vue/composition-api";
import { JSONPath } from "jsonpath-plus";

const TEXT_SNIPPET_LENGTH = 7;

export default defineComponent({
  props: {
    match: {
      type: Object as PropType<Match>,
      required: true
    },
    testCase: {
      type: String,
      required: true
    }
  },
  computed: {
    testValue() {
      try {
        return (
          JSONPath({
            path: this.match.path,
            json: JSON.parse(this.testCase),
            resultType: "value"
          }).at(0) ?? ""
        );
      } catch {
        return this.testCase;
      }
    },
    snippetBeforeMatch() {
      const firstCharIndex = this.match.position;
      return this.testValue.substring(firstCharIndex - TEXT_SNIPPET_LENGTH, firstCharIndex);
    },
    snippetAfterMatch() {
      const firstCharIndex = this.match.position + this.match.length;
      return this.testValue.substring(firstCharIndex, firstCharIndex + TEXT_SNIPPET_LENGTH);
    }
  }
});
