
























































































































































import {
  ComponentPublicInstance,
  computed,
  defineComponent,
  inject,
  ref,
  toRef,
  toRefs
} from "@vue/composition-api";
import { useQuery } from "vue-query";
import KeywordSuggestionService from "@/services/keyword-suggestion-service";
import { KeywordWithType, KEYWORD_TYPES, SEARCH_TYPES, SortBy } from "@/models/keyword-suggestion";
import { useLocalStorageString } from "./useLocalStorage";
import { useDebouncedRef } from "./useDebounce";
import KeywordSuggestionChip from "./keyword-suggestion-chip.vue";
import RuleKeywordAddButton from "./rule-keyword-add-button.vue";

export default defineComponent({
  components: { KeywordSuggestionChip, RuleKeywordAddButton },
  props: {
    value: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    exclusions: {
      type: Array,
      required: true
    }
  },
  emits: {
    input: null,
    "click:add": (newTerm: KeywordWithType, parameterName: string) => ({ parameterName, newTerm })
  },

  setup(props, { emit }) {
    const { value } = toRefs(props);

    const keywordService = inject("KeywordSuggestionService") as KeywordSuggestionService;

    const menu = ref<null | ComponentPublicInstance>(null);
    const refs = ref<ComponentPublicInstance[] | null>(null);
    const itemGroup = ref<ComponentPublicInstance | null>(null);
    const textfield = ref<ComponentPublicInstance | null>(null);

    const isMenuOpen = ref(false);
    const focusedMenuIndex = ref(-1);

    const searchType = useLocalStorageString("searchType", SEARCH_TYPES[0].value);
    const enableExclusions = useLocalStorageString("exclusionsEnabled", false);
    const exclusions = toRef(props, "exclusions");

    const debouncedValue = useDebouncedRef(value, 500);
    const debouncedExclusions = useDebouncedRef(exclusions, 500);

    const exclusionsToApply = computed(() => {
      return enableExclusions.value ? debouncedExclusions.value : [];
    });

    const queryEnabled = computed(() => {
      return Boolean(isMenuOpen.value && debouncedValue.value && debouncedValue.value.length > 0);
    });

    const { data, isFetching, isError, error } = useQuery(
      ["keywords", debouncedValue, searchType, exclusionsToApply],
      async () => {
        const result = await keywordService.getSuggestions(
          debouncedValue.value,
          searchType.value,
          exclusionsToApply.value as string[]
        );
        return result;
      },
      {
        cacheTime: Infinity,
        staleTime: Infinity,
        enabled: queryEnabled
      }
    );

    const onClick = () => {
      isMenuOpen.value = true;
    };

    const setValue = (value: KeywordWithType) => {
      if (value.type === KEYWORD_TYPES.nextWord) {
        emit("input", `${debouncedValue.value} ${value.name}`);
      }
      if (value.type === KEYWORD_TYPES.previousWord) {
        emit("input", `${value.name} ${debouncedValue.value}`);
      } else {
        isMenuOpen.value = false;
      }
    };

    const handleInput = (value: string | KeywordWithType) => {
      if (typeof value === "string") emit("input", value);
      else setValue(value);
    };
    const handleKeydown = () => {
      if (!refs.value || refs.value.length < 1) return;
      itemGroup.value?.$el.focus();
    };
    const handleKeyup = (index: number) => {
      if (index === 0) textfield.value?.$el.focus();
    };

    const changeMenuFocus = (changeBy: number) => {
      if (!refs.value) return;
      const indexToFocus = focusedMenuIndex.value + changeBy;
      if (indexToFocus < 0 || indexToFocus >= refs.value.length) return;
      refs.value[indexToFocus].$el.focus();
    };

    const addSuggestionClicked = (value: KeywordWithType, parameterName: string) => {
      emit("click:add", value, parameterName);
      clickedSuggestions.value.push(value);
    };

    const clickedSuggestions = ref<KeywordWithType[]>([]);

    return {
      data,
      isFetching,
      isError,
      error,
      handleInput,
      KEYWORD_TYPES,
      onClick,
      isMenuOpen,
      refs,
      handleKeydown,
      menu,
      changeMenuFocus,
      focusedMenuIndex,
      setValue,
      itemGroup,
      searchType,
      SEARCH_TYPES,
      textfield,
      handleKeyup,
      debouncedValue,
      enableExclusions,
      debouncedExclusions,
      SortBy,
      addSuggestionClicked,
      clickedSuggestions
    };
  },
  computed: {
    flattenedData(): KeywordWithType[] {
      if (!this.data) return [];
      return [
        ...this.data.nextWords.map(x => ({
          ...x,
          type: KEYWORD_TYPES.nextWord
        })),
        ...this.data.previousWords.map(x => ({
          ...x,
          type: KEYWORD_TYPES.previousWord
        }))
      ];
    },
    sortedFlatData() {
      const result = [...this.flattenedData].sort((a, b) => {
        const sortBy = SEARCH_TYPES.find(x => x.value === this.searchType)?.sortFn;
        if (!sortBy) return 0;
        return sortBy(a, b);
      });
      result.unshift({
        name: this.value,
        appearenceInCommodity: this.data?.searchedTerm.appearenceInCommodity || 0,
        appearenceInDangerousCargo: this.data?.searchedTerm.appearenceInDangerousCargo || 0,
        allAppearence: this.data?.searchedTerm.allAppearence || 0,
        type: KEYWORD_TYPES.original
      });
      return result;
    }
  }
});
