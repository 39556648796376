













import { Component, Vue, Prop } from "vue-property-decorator";
import RuleParameterEditor from "@/components/library-maintenance/rule-parameter-editor.vue";
import { Field } from "@/models/form";

@Component({ components: { RuleParameterEditor } })
export default class RuleParameterEditorTabs extends Vue {
  @Prop({ required: true }) field!: Field;

  get tabNames() {
    const names = this.field.value.map((value: any) => value.name);
    return [...new Set(names)];
  }

  tab = 0;
}
