




















































































































import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Field } from "@/models/form";
import HdQueryEditor from "../form/hd-query-editor.vue";
import EvaluationService, { EvaluationResult } from "@/services/evaluation-service";
import RuleMatchHighlight from "./rule-match-highlight.vue";
import ScreenResultsService from "@/services/screen-results-service";
import { AxiosError } from "axios";

const Mode = {
  ScreeningId: 0,
  Raw: 1
} as const;

type ModeValue = typeof Mode[keyof typeof Mode];
type State = {
  screeningIdText: string;
  testCaseText: string;
  lastPayloadEvaluated: string | null;
  lastFieldEvaluated: string | null;
  lastScreeningIdEvaluated: string | null;
  testPerformedYet: boolean;
  evaluationResults: EvaluationResult;
  evaluationLoading: boolean;
};

const DefaultValues: Readonly<State> = {
  screeningIdText: "",
  testCaseText: "",
  lastPayloadEvaluated: null,
  lastFieldEvaluated: null,
  lastScreeningIdEvaluated: null,
  testPerformedYet: false,
  evaluationResults: { matches: [] },
  evaluationLoading: false
};

@Component({
  components: {
    HdQueryEditor,
    RuleMatchHighlight
  }
})
export default class RuleTest extends Vue {
  @Inject() EvaluationService!: EvaluationService;
  @Inject() ScreenResultsService!: ScreenResultsService;

  @Prop({ required: true }) field!: Field;

  screeningIdText = DefaultValues.screeningIdText;
  testCaseText = DefaultValues.testCaseText;
  lastPayloadEvaluated = DefaultValues.lastPayloadEvaluated;
  lastFieldEvaluated = DefaultValues.lastFieldEvaluated;
  lastScreeningIdEvaluated = DefaultValues.lastScreeningIdEvaluated;
  testPerformedYet = DefaultValues.testPerformedYet;
  evaluationResults = DefaultValues.evaluationResults;
  evaluationLoading = DefaultValues.evaluationLoading;
  mode: ModeValue = Mode.ScreeningId;
  Mode = Mode;

  async evaluate() {
    const payload =
      this.mode === Mode.Raw
        ? this.handleTestCaseAsJson(this.testCaseText)
        : await this.getScreening();
    if (!payload) return;
    this.evaluationLoading = true;
    try {
      this.evaluationResults = await this.EvaluationService.evaluateCondition(
        this.field.value,
        payload
      );
      this.lastPayloadEvaluated = this.testCaseText;
      this.lastFieldEvaluated = this.field.value;
      this.lastScreeningIdEvaluated = this.screeningIdText;
    } catch {
    } finally {
      this.testPerformedYet = true;
      this.evaluationLoading = false;
    }
  }

  screeningLoading = false;
  lastScreeningRequested: string | null = null;
  screeningError: string | boolean = false;

  async getScreening() {
    this.screeningLoading = true;
    try {
      const screening = await this.ScreenResultsService.readScreening(this.screeningIdText);

      const prettyScreeningString = JSON.stringify(screening, null, 2);
      const screeningString = JSON.stringify(screening);
      this.lastScreeningRequested = prettyScreeningString;
      return screeningString;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status === 404) this.screeningError = "Screening not found";
      else {
        this.screeningError = "Error Getting Screening";
      }

      return null;
    } finally {
      this.screeningLoading = false;
    }
  }

  get areMatchesStale() {
    return (
      this.lastPayloadEvaluated !== this.testCaseText ||
      this.lastFieldEvaluated !== this.field.value ||
      this.lastScreeningIdEvaluated !== this.screeningIdText
    );
  }

  handleTestCaseAsJson(testCase: string): string {
    try {
      const parsed = JSON.parse(testCase);
      if (typeof parsed !== "object") throw new Error();
      return JSON.stringify(parsed);
    } catch {
      return JSON.stringify({ field: testCase });
    }
  }

  reset() {
    const newValues = structuredClone(DefaultValues);

    Object.assign(this, newValues);
  }
}
