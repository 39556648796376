import { Ref, ref, watch } from "@vue/composition-api";

export const useDebouncedRef = <T>(value: Ref<T>, delay: number) => {
  const debouncedValue = ref<T>(value.value) as Ref<T>;
  const timeout = ref<null | number>(null);

  watch(
    () => value.value,
    newValue => {
      if (timeout.value) {
        clearTimeout(timeout.value);
      }
      timeout.value = setTimeout(() => {
        debouncedValue.value = newValue;
      }, delay);
    }
  );

  return debouncedValue;
};
