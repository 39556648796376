<template>
  <v-chip
    small
    :color="searchTypeViewData.color"
    text-color="white"
    class="darken-3"
    v-if="searchTypeViewData.getFn(suggestion) > 0"
  >
    {{ searchTypeViewData.getFn(suggestion) }}
  </v-chip>
</template>

<script>
import { computed, defineComponent } from "@vue/composition-api";
import { SEARCH_TYPES } from "@/models/keyword-suggestion";

export default defineComponent({
  props: {
    suggestion: {
      type: Object,
      required: true
    },
    searchType: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const getSearchTypeViewData = type => {
      const searchType = SEARCH_TYPES.find(x => x.value === type);
      if (!searchType) return null;
      return searchType;
    };

    const searchTypeViewData = computed(() => {
      return getSearchTypeViewData(props.searchType) || SEARCH_TYPES[0];
    });

    return {
      searchTypeViewData
    };
  }
});
</script>
