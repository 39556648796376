










































import { defineComponent, inject, ref } from "@vue/composition-api";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true
    }
  },
  emits: {
    "click:add": (parameterName: string) => parameterName
  },
  setup(props, { emit }) {
    const allParameterNames = inject<string[]>("allParameterNames");

    const btn = ref(null);
    const isMenuOpen = ref(false);

    const menuItemClicked = (parameterName: string) => {
      emit("click:add", parameterName);
    };

    return { isMenuOpen, btn, allParameterNames, menuItemClicked };
  }
});
