












































































































































































































































import { Component, Vue, Prop, Provide } from "vue-property-decorator";
import EcTextField from "common-components/src/components/form/ec-text-field.vue";
import { TableHeader } from "@/components/layout/models/table.d";
import { Field } from "@/models/form";
import { RuleParameterValue } from "@/models/library-maintenance";
import { operators } from "@/models/dropdowns";
import RuleKeywordSuggestion from "./rule-keyword-suggestion.vue";
import { RULE_PARAMETER_TYPES } from "@/models/rule";
import { isFeatureEnabled } from "@/config";
import { KeywordWithType } from "@/models/keyword-suggestion";

type GroupState = {
  name: string;
  startingIndex: number;
  endingIndex: number;
  isOpen: boolean;
};

@Component({
  components: {
    EcTextField,
    RuleKeywordSuggestion
  }
})
export default class RuleParameterEditor extends Vue {
  @Prop({ required: true }) field!: Field;
  @Prop({ default: false }) foldingEnabled!: boolean;
  @Prop({ required: false }) nameFilter!: string | undefined;

  parameterError = "";
  RULE_PARAMETER_TYPES = RULE_PARAMETER_TYPES;
  MAX_GROUP_SIZE = 5;

  get keywordSuggestionsEnabled() {
    return isFeatureEnabled("KeywordSuggestion");
  }

  isFolded(index: number) {
    if (!this.foldingEnabled) return false;
    const state = this.getGroupState(index);
    if (!state || state.isOpen) return false;

    return index - state.startingIndex > this.MAX_GROUP_SIZE;
  }
  shouldDisplayFoldButton(index: number) {
    if (!this.foldingEnabled) return false;
    const state = this.getGroupState(index);
    if (!state) return false;
    const groupCanBeFolded = state.endingIndex - state.startingIndex > this.MAX_GROUP_SIZE;
    return groupCanBeFolded && index === state.endingIndex && state.isOpen;
  }
  shouldDisplayUnfoldButton(index: number) {
    if (!this.foldingEnabled) return false;
    const state = this.getGroupState(index);
    if (!state) return false;
    const groupCanBeFolded = state.endingIndex - state.startingIndex > this.MAX_GROUP_SIZE;
    return groupCanBeFolded && index === state.endingIndex && !state.isOpen;
  }
  fold(index: number) {
    const state = this.getGroupState(index);
    if (!state) return;
    this.setGroupState(index, x => ({ ...x, isOpen: false }));
  }
  unfold(index: number) {
    const state = this.getGroupState(index);
    if (!state) return;
    this.setGroupState(index, x => ({ ...x, isOpen: true }));
  }

  getAllTerms(filterEmptyStrings = false) {
    return this.field.value.filter(
      (item: any) => item.type === RULE_PARAMETER_TYPES.Term && (!filterEmptyStrings || item.value)
    );
  }

  @Provide("allParameterNames")
  allParameterNames = (() => {
    const names = this.getAllTerms().map((item: any) => item.name);
    const dedupedNames = [...new Set(names)];
    return dedupedNames;
  })();

  get dedupedAllTerms() {
    const terms = this.getAllTerms(true).map((item: any) => item.value);
    const dedupedTerms = [...new Set(terms)];
    return dedupedTerms.map((item: any) => item.toUpperCase());
  }

  groupings = this.createGroupings();

  groupIndexMemoMap = new Map<number, number>();

  getGroupIndex(index: number) {
    const inMemoIndex = this.groupIndexMemoMap.get(index);
    if (inMemoIndex !== undefined) {
      return inMemoIndex;
    }

    const foundIndex = this.groupings.findIndex(
      group => group.startingIndex <= index && group.endingIndex >= index
    );
    if (foundIndex === -1) return 0;
    this.groupIndexMemoMap.set(index, foundIndex);
    return foundIndex;
  }

  getGroupState(index: number) {
    return this.groupings[this.getGroupIndex(index)];
  }

  setGroupState(index: number, setNewState: (x: GroupState) => GroupState) {
    const indexOfGroup = this.getGroupIndex(index);
    this.$set(this.groupings, indexOfGroup, setNewState(this.groupings[indexOfGroup]));
  }

  createGroupings() {
    const namesGroups: GroupState[] = [];
    (this.field.value as any[]).forEach((item: { name: string }, i) => {
      if (namesGroups.length === 0 || namesGroups[namesGroups.length - 1].name !== item.name) {
        namesGroups.push({
          name: item.name,
          startingIndex: i,
          endingIndex: i,
          isOpen: false
        });
      } else {
        namesGroups[namesGroups.length - 1].endingIndex = i;
      }
    });

    return namesGroups;
  }

   onTermClicked(
    searchTerm: KeywordWithType,
    parameterName: string,
    rowClicked: any,
    rowIndex: number
  ) {
    let newValue: string;
    if (searchTerm.type === "nextWord") {
      newValue = `${rowClicked.value} ${searchTerm.name}`;
    } else {
      newValue = `${searchTerm.name} ${rowClicked.value}`;
    }

    let newIndex = rowIndex + 1;
    let newName = parameterName;
    if (rowClicked.name !== parameterName) {
      newIndex = this.field.value.findLastIndex((item: any) => item.name === parameterName) + 1;
      newName = parameterName;
    }

    this.addRow(newIndex, newName, rowClicked.type, newValue);
  }

  parameterHeaders: TableHeader[] = [
    {
      text: "Name",
      value: "name",
      sortable: false,
      cellClass: "text-no-wrap px-2",
      width: "14ch",
      align: "center"
    },
    {
      text: "Value",
      value: "value",
      sortable: false
    },
    {
      text: "Operator",
      value: "operator",
      sortable: false,
      width: "140px",
      class: "text-no-wrap px-0"
    },
    {
      text: "Suppressed",
      value: "is-suppressed",
      sortable: false,
      align: "center",
      class: "text-no-wrap px-1",
      width: "10px"
    },
    {
      text: "Regex",
      value: "is-regex",
      sortable: false,
      align: "center",
      class: "text-no-wrap px-1",
      width: "10px"
    },
    {
      text: "Unordered",
      value: "unordered",
      sortable: false,
      align: "center",
      class: "text-no-wrap px-1",
      width: "10px"
    },
    {
      text: "Fuzziness",
      value: "fuzziness",
      sortable: false,
      width: "7ch",
      align: "center",
      class: "text-no-wrap px-0"
    },
    {
      text: "Proximity",
      value: "proximity",
      sortable: false,
      width: "7ch",
      align: "center",
      class: "text-no-wrap px-0"
    },
    {
      text: "",
      value: "add",
      sortable: false,
      width: "35px"
    },
    {
      text: "",
      value: "remove",
      sortable: false,
      width: "35px"
    }
  ];

  operators: any[] = operators;

  removeRow(index: number) {
    this.field.value.splice(index, 1);

    const firstGroupIndex = this.getGroupIndex(index);
    this.groupIndexMemoMap.clear();
    for (let i = firstGroupIndex; i < this.groupings.length; i++) {
      this.groupings[i].endingIndex--;
      if (i !== firstGroupIndex) {
        this.groupings[i].startingIndex--;
      }
    }
  }

  addRow(newIndex: number, name: string, type: number, value = "") {
    const array = this.field.value;
    const newValue: RuleParameterRow = {
      name,
      value,
      type,
      "allow-multiple": true,
      unordered: false,
      "is-regex": false,
      fuzziness: 0,
      "is-suppressed": false,
      proximity: 0
    };
    array.splice(newIndex, 0, newValue);

    // propagate new indexes in groupings
    const firstGroupIndex = this.getGroupIndex(newIndex - 1);
    this.groupIndexMemoMap.clear();
    for (let i = firstGroupIndex; i < this.groupings.length; i++) {
      this.groupings[i].endingIndex++;
      if (i !== firstGroupIndex) {
        this.groupings[i].startingIndex++;
      }
    }

    if (newIndex == array.length) window.scrollTo(0, document.body.scrollHeight + 100);
  }

  moreThanOneValue(valueName: string) {
    const valuesCount = this.field.value.filter(
      (parmeter: { name: string }) => parmeter.name === valueName
    ).length;
    return valuesCount > 1;
  }

  isParameterLastValue(value: string, name: string, index: number) {
    const array = this.field.value as RuleParameterValue[];
    return index === array.length - 1 || array[index + 1].name !== name;
  }

  regexChangedFor(item: any) {
    if (item["is-regex"]) {
      item.fuzzinessEnabled = false;
    } else {
      item.fuzzinessEnabled = false;
    }
  }

  nextParameter(event: KeyboardEvent) {
    event.target;
  }
}

declare interface RuleParameterRow {
  name: string;
  value: string;
  type: number;
  "allow-multiple": boolean;
  unordered: boolean;
  fuzziness: number;
  "is-regex": boolean;
  "is-suppressed": boolean;
  operator?: string;
  proximity: number;
}
