












































































import { Component, Inject, Vue, Watch, Ref } from "vue-property-decorator";

import RuleService from "@/services/rule-service";
import LibraryService from "@/services/library-service";
import TemplateService from "@/services/template-service";
import Card from "@/components/material/Card.vue";
import { SnackbarOptions } from "@/models/form";
import EcSnackBar from "common-components/src/components/form/ec-snack-bar.vue";
import RuleEditor from "@/components/library-maintenance/rule-editor.vue";
import {
  Library,
  Rule,
  RuleUpsertModel,
  Template,
} from "@/models/library-maintenance.d";
import { backOr } from "@/router";

import { getModule } from "vuex-module-decorators";
import AppState from "@/store/modules/app-module";
import { ApiError } from "../models/hal.d";

const appState = getModule(AppState);

@Component({
  components: {
    Card,
    RuleEditor,
    EcSnackBar,
  },
})
export default class RuleView extends Vue {
  @Inject() RuleService!: RuleService;

  @Inject() LibraryService!: LibraryService;

  @Inject() TemplateService!: TemplateService;

  libraryId!: string;

  library: Library = {} as Library;

  libraryTitle = '';

  ruleId?: string;

  rule: Rule = {} as Rule;

  templateList: Template[] = [];

  metadataKeys = [
    { header: 'Select a predfined metadata key or create a custom one' },
    { text: 'category' },
    { text: 'sub-category' },
    { text: 'priority' },
  ];

  apiErrors: ApiError[] = [];

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  loading = false;

  editing() {
    return Boolean(this.ruleId);
  }

  @Ref() editor!: RuleEditor;

  @Watch('$route', { immediate: true })
  async onRouteChanged(value: any): Promise<void> {
    this.libraryId = value.params.libraryId;
    this.ruleId = value.params.ruleId;

    await this.loadData();
  }

  async loadData() {
    if (!this.libraryId) {
      return Promise.reject(new Error("library id is undefined"));
    }

    this.loading = true;
    const library = await this.LibraryService.readSingle(this.libraryId);
    this.library = library;
    this.libraryTitle = `Library: ${this.library.name}`;

    const templateList = await this.TemplateService.listTemplates(this.libraryId);
    this.templateList = templateList._embedded.templates;

    if (!this.ruleId) {
      this.loading = false;
      return Promise.resolve();
    }

    try {
      const rule = await this.RuleService.readSingle(this.libraryId, this.ruleId);
      this.rule = rule;
      this.loading = false;
    } catch (error) {
      this.$router.replace({ name: 'not-found' });
    }
  }

  @Watch('rule')
  onRuleUpdated() {
    appState.setTitle(`Rule: ${this.rule?.name}`);
  }

  back() {
    backOr({ name: 'rules', params: { libraryId: this.libraryId } });
  }

  async save(ruleUpdate: RuleUpsertModel) {
    this.apiErrors = [];
    if (!this.editing()) {
      await this.createRule(ruleUpdate);
    } else {
      await this.updateRule(ruleUpdate);
    }
  }

  async createRule(ruleUpdate: RuleUpsertModel) {
    this.snackbarOptions = EcSnackBar.makeProgressOptions("Creating rule ...");

    try {
      const rule = await this.RuleService.createRule(
        this.libraryId,
        ruleUpdate
      );
      this.snackbarOptions.value = false;
      this.$nextTick(() => {
        this.snackbarOptions = EcSnackBar.makeSuccessfulOptions(
          "Successfully created"
        );
      });

      this.rule = rule;
      this.$router.replace({
        name: "rule",
        params: { libraryId: this.libraryId, ruleId: rule.id },
      });
      this.ruleId = rule.id;

    } catch (http: any) {
      this.snackbarOptions = EcSnackBar.makeDefaultOptions();
      this.apiErrors = http.response?.data?.errors || [];
    }
  }

  async updateRule(ruleUpdate: RuleUpsertModel) {
    this.snackbarOptions = EcSnackBar.makeProgressOptions("Saving rule ...");

    try {
      const rule = await this.RuleService.updateRule(
        this.libraryId,
        ruleUpdate,
        this.ruleId
      );

      this.rule = rule;
      this.snackbarOptions.value = false;
      this.$nextTick(() => {
        this.snackbarOptions = EcSnackBar.makeSuccessfulOptions(
          "Successfully updated"
        );
      });
    } catch (http: any) {
      this.snackbarOptions = EcSnackBar.makeDefaultOptions();
      this.apiErrors = http.response?.data?.errors || [];
    }
  }

  formValidationError() {
    if (this.editor.checkZeroWidthSpace()) {
      this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(
        "Zero width spaces are not allowed"
      );
    } else {
      this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(
        "Please correct any errors before saving"
      );
    }
  }

  async setEnabledState(enable: boolean) {
    if (!this.ruleId) return;

    try {
      const text = enable
        ? 'If you enable this rule, it is likely that it will start to be processed and start generating hits.'
        : 'If you disable this rule, it will no longer be processed and will not generate hits.';

      appState.openDialog({
        title: enable ? 'Enable Rule?' : 'Disable Rule?',
        text: `<p>Are you sure?</p><p>${text}</p>`,
        actions: [{
          name: enable ? 'Enable' : 'Disable',
          color: 'warning',
          handler: async () => {
            this.snackbarOptions = EcSnackBar.makeProgressOptions('Changing rule ...');
            await this.RuleService.setEnabledState(
              this.libraryId,
              this.ruleId ?? "",
              enable)
              .then(() => {
                this.rule.enabled = enable;

                this.snackbarOptions.value = false;
                this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeSuccessfulOptions('Changed rule'); });
              })
              .catch((error: Error) => {
                this.snackbarOptions.value = false;
                this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to change rule: ${error.message}`); });
              })
          },
        }, {
          name: 'Cancel',
          handler: () => Promise.resolve(false),
        }],
      });
    } catch (error) {
      this.snackbarOptions.value = false;
      this.$nextTick(() => {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(
          "Failed to change rule"
        );
      });
    }
  }

  async deleteRule() {
    if (!this.ruleId) return;

    try {
      appState.openDialog({
        title: 'Delete Rule?',
        text: '<p>Are you sure?</p><p>If you delete this rule, it will no longer be processed and will not generate hits.</p>',
        actions: [{
          name: 'Delete',
          color: 'warning',
          handler: async () => {
            this.snackbarOptions = EcSnackBar.makeProgressOptions('Deleting rule ...');
            this.RuleService.deleteRule(this.libraryId, this.ruleId ?? "")
              .then(() => {
                  this.snackbarOptions.value = false;
                  this.back();
                }
              )
              .catch((error: Error) => {
                this.snackbarOptions.value = false;
                this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to delete rule: ${error.message}`); });
              }
            )
          },
        },
        {
          name: 'Cancel',
          color: 'primary',
          handler: () => Promise.resolve(false),
        }],
      });
    } catch (error) {
      this.snackbarOptions.value = false;
      this.$nextTick(() => {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(
          "Failed to delete rule"
        );
      });
    }
  }
}
